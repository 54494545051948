html {
  scroll-behavior: smooth;
}

* {
  margin: 0;
  padding: 0;
  font-family: 'Poppins', sans-serif;
  box-sizing: border-box;
}

body {
  background: #080808;
  color: #ffffff;
}

section {
  display: flex;
  min-height: 100vh;
  justify-content: center;
  align-items: center;
}


#header {
  display: block;
  width: 100%;
  height: 100vh;
  background: linear-gradient(to bottom, #2B376E, #080808);
  background-size: cover;
  background-position: center;
}

.container {
  padding: 10px 10%;
}

nav {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
  position: fixed;
  background: #273262;
  margin: 0;
  padding-top: 10px;
  padding-bottom: 10px;
  width: 100%;
  top: 0;
  left: 0;
  z-index: 100;
}

nav ul {
  display: flex;
  justify-content: center;
  align-items: center;
}

nav ul li {
  display: flex;
  justify-content: center;
  align-items: center;
  list-style: none;
  margin: 10px 20px;
  transition: 0.5s;
}

nav ul li a {
  color: #ffffff;
  text-decoration: none;
  font-size: 18px;
  position: relative;
}

nav ul li .styled-dropdown {
  font-size: 18px;
}

nav ul li a::after,
nav ul li .styled-dropdown:hover::after {
  content: '';
  width: 0;
  height: 3px;
  background: #64d9fa;
  position: absolute;
  left: 0;
  bottom: -6px;
  transition: all 0.3s ease;
}

nav ul li a:hover::after,
nav ul li .styled-dropdown:hover::after {
  width: 100%;
}

.active,
.active::after {
  font-size: 20px;
  width: 100%;
}

nav ul li:hover {
  scale: 1.1;
}

.logo {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 300px;
  font-size: 35px;
  font-weight: 600;
  color: #57c4e1;
  margin-left: 50px;
}

.logo span {
  color: #ffffff;
}

.header-text {
  margin-top: 20%;
  font-size: 30px;
}

.header-text h1 {
  font-size: 60px;
  margin-top: 20px;
}

.header-text span {
  color: #57c4e1;
}

.hero {
  display: flex;
  height: 100vh;
  justify-content: space-between;

}

.language-selector {
  display: flex;
  align-items: center;
  justify-content: center;
}

.language-selector label {
  margin-right: 10px;
  font-weight: bold;
}

.styled-dropdown {
  padding: 10px;
  font-size: 1rem;
  border-radius: 8px;
  border-color: #f8f9fa00;
  background-color: #f8f9fa00;
  transition: all 0.5s ease-in-out;
  outline: none;
  cursor: pointer;
  color: #eee;
}

/* Optional: To style the dropdown options */
.styled-dropdown option {
  padding: 10px;
}

@keyframes blink {
  from {
    opacity: 0%;
  }

  to {
    opacity: 100%;
  }
}

.type-writer::after {
  content: "|";
  animation: blink 1s infinite;
}


.scroll-down {
  display: flex;
  justify-content: center;
  align-items: end;
  writing-mode: vertical-lr;
}

.scroll-down a {
  text-decoration: none;
  color: #ffffff;
  transition: 0.5s;

}

.scroll-down a:hover {
  color: #57c4e1;
}

.scroll-down p {
  transition: 0.5s;
  font-size: 20px;
}

.scroll-down p:hover {
  transform: scale(1.1);
}

/* ----------------------- ABOUT ------------------------ */
#about {
  padding: 80px 0;
  color: #ababab;
}

.row {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}

.about-col-1 {
  flex-basis: 35%;
}

.about-col-1 img {
  width: 100%;
  border-radius: 15px;
}

.about-col-2 {
  flex-basis: 60%;
}

.sub-title {
  font-size: 60px;
  font-weight: 600;
  color: #ffffff;
}

.tab-titles {
  display: flex;
  margin: 20px 0 40px;
}

.tab-links {
  margin-right: 50px;
  font-size: 18px;
  font-weight: 500;
  cursor: pointer;
  position: relative;
  transition: all 0.5s;
}

.tab-links::after {
  content: '';
  width: 0;
  height: 3px;
  background-color: #57c4e1;
  position: absolute;
  left: 0;
  bottom: -8px;
  transition: 0.5s;
}

.tab-links.active-link::after {
  width: 50%;
}

.tab-links.active-link {
  scale: 1.1;
  color: #ffffff;
}

.tab-contents ul li {
  list-style: none;
  margin: 10px 0;
}

.tab-contents ul li span {
  color: #57c4e1;
  font-size: 14px;
}

.tab-contents {
  display: none;
}

.tab-contents.active-tab {
  display: block;
}

/* ----------------------- Services --------------------- */

#services {
  padding: 30px 0;
}

.services-list {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  grid-gap: 40px;
  margin-top: 50px;
}

.services-list div {
  background: #262626;
  padding: 40px;
  font-size: 13px;
  font-weight: 300;
  border-radius: 10px;
  transition: transform 0.5s, background 0.5s;
}

.services-list div:hover {
  background: #57c4e1;
  transform: translateY(-10px);
}

.services-list div i {
  font-size: 50px;
  margin-bottom: 30px;
}

.services-list div h2 {
  font-size: 30px;
  font-weight: 500;
  margin-bottom: 15px;
}

.services-list div a {
  text-decoration: none;
  color: #ffffff;
  font-size: 12px;
  margin-top: 20px;
  display: inline-block;
}

.services-list div p {
  font-size: 16px;
}


/* ----------------------- portfolio --------------------- */

#portfolio {
  padding: 50px 0;
}

.work-list {
  display: grid;
  grid-template-columns: repeat(3, minmax(250px, 1fr));
  grid-gap: 40px;
  margin-top: 50px;
}

.work {
  border-radius: 10px;
  position: relative;
  overflow: hidden;
}

.work img {
  width: 100%;
  border-radius: 10px;
  display: block;
  transition: transform 0.5s;
}

.work:hover img {
  transform: scale(1.1);
}

.work:hover .layer {
  height: 100%;
}

.layer {
  width: 100%;
  height: 0%;
  background: linear-gradient(rgba(0, 0, 0, 0.6), #57c4e1);
  border-radius: 10px;
  position: absolute;
  left: 0;
  bottom: 0;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 0 40px;
  text-align: center;
  font-size: 14px;
  transition: height 0.5s;
}

.layer h3 {
  font-weight: 500;
  margin-bottom: 20px;
}

.layer a {
  margin-top: 20px;
  color: #57c4e1;
  text-decoration: none;
  font-size: 18px;
  line-height: 60px;
  background: #ffffff;
  width: 60px;
  height: 60px;
  border-radius: 50%;
  text-align: center;
}

.btn {
  display: block;
  margin: 50px auto;
  width: fit-content;
  border: 1px solid #57c4e1;
  padding: 14px 50px;
  border-radius: 6px;
  text-decoration: none;
  color: #ffffff;
  transition: background 0.5s;
}

.btn:hover {
  background: #57c4e1;
}


/* ----------------------- contact --------------------- */

.contact-left {
  flex-basis: 35%;
}

.contact-right {
  flex-basis: 60%;
}

.contact-left p {
  margin-top: 30px;
}

.contact-left p i {
  color: #57c4e1;
  margin-right: 15px;
  font-size: 25px;
}

.social-icons {
  margin-top: 30px;
}

.social-icons a {
  text-decoration: none;
  font-size: 30px;
  margin-right: 15px;
  color: #ababab;
  display: inline-block;
  transition: transform 0.5s;
}

.social-icons a:hover {
  color: #57c4e1;
  transform: translateY(-5px);
}

.btn:hover {
  cursor: pointer;
}

.btn.btn2 {
  display: inline-block;
  background: #57c4e1;
}

.contact-right form {
  width: 100%;
}

form input,
form textarea {
  width: 100%;
  border: 0;
  outline: none;
  background: #262626;
  padding: 15px;
  margin: 15px 0;
  color: #ffffff;
  border-radius: 6px;
}

form .btn2 {
  padding: 14px 60px;
  font-size: 18px;
  margin-top: 20px;
  cursor: pointer;
  transition: 'background-color 0.3s ease';
  width: 188px;
}

#contact {
  height: 90vh;
}

/* ----------------------- footer --------------------- */
.copyright {
  position: relative;
}

.copyright p {
  width: 100%;
  text-align: center;
  padding: 3vh 0;
  background: #262626;
  font-weight: 300;
}

.copyright p i {
  color: #57c4e1;
}

.up {
  position: absolute;
  width: 4rem;
  height: 4rem;
  background-color: #eee;
  top: -2rem;
  right: 5rem;
  border-radius: 100%;
  display: flex;
  animation: pulse 2s infinite;
  cursor: pointer;
  z-index: 9;
  transition: background-color 0.3s;
}

.up:hover {
  background-color: #57c4e1;
}

.up i {
  color: #16162d;
  font-size: 2rem;
  margin: auto;

}

/* ----------------------- css for small screens --------------------- */
nav .fas {
  display: none;
}

@keyframes pulse {
  0% {
    box-shadow: 0 0 0 0 rgba(87, 196, 225, 4)
  }

  70% {
    box-shadow: 0 0 0 2rem rgba(87, 196, 225, 0)
  }

  100% {
    box-shadow: 0 0 0 0 rgba(87, 196, 225, 0)
  }
}

@media only screen and (max-width: 1100px) {
  .logo {
    display: none;
  }

  #sidemenu {
    display: flex;
    width: 100%;
  }

  .header-text h1 {
    font-size: 40px;
  }

  .header-text {
    height: 50vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
}

@media only screen and (max-width: 1100px) {
  .work-list {
    display: grid;
    grid-template-columns: repeat(2, minmax(250px, 1fr));
    grid-gap: 40px;
    margin-top: 50px;
  }

  h1.sub-title {
    font-size: 50px;
  }
}

@media only screen and (max-width: 420px) {
  .logo {
    width: 250px;
    font-size: 30px;
  }

  .header-text {
    margin-top: 100%;
    font-size: 16px;
  }

  .header-text h1 {
    font-size: 20px;
  }

  .scroll-down {
    display: none;
  }

  nav .fas {
    display: block;
    font-size: 25px;
  }

  nav ul {
    background: #57c4e1;
    position: fixed;
    top: 0;
    right: -200px;
    width: 200px;
    height: 100vh;
    padding-top: 50px;
    z-index: 2;
    transition: right 0.5s;
  }

  nav ul li {
    display: block;
    margin: 25px;
  }

  nav ul .fas {
    position: absolute;
    top: 25px;
    left: 25px;
    cursor: pointer;
  }

  .sub-title {
    font-size: 40px;
  }

  .about-col-1,
  .about-col-2 {
    flex-basis: 100%;
  }

  .about-col-1 {
    margin-bottom: 30px;
  }

  .about-col-2 {
    font-size: 14px;
  }

  .tab-links {
    font-size: 16px;
    margin-right: 20px;
  }

  .work-list {
    grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  }

  .contact-left,
  .contact-right {
    flex-basis: 100%;
  }

  .copyright {
    font-size: 11px;
  }

}